import {DailyReport} from "./interfaces/DailyReport/DailyReport";
import {DailyReportContent} from "./interfaces/DailyReport/DailyReportContent";
import {DailyReportReply} from "./interfaces/DailyReport/DailyReportReply";

/**
 * 日報データモデル
 */
export class DailyReportModel implements DailyReport {
    dailyReportId: number;
    sheetId: number;
    reportDate: string;
    personId: number;
    reportComment: string;
    reportContents: DailyReportContent[] = [];
    reportReplies: DailyReportReply[] = [];

    /**
     * コンストラクタ
     *
     *
     */
    public constructor(
        dailyReportId: number,
        sheetId: number,
        reportDate: string,
        personId: number,
        reportComment: string,
        reportContents?: DailyReportContent[],
        reportReplies?: DailyReportReply[]
    ) {
        this.dailyReportId = dailyReportId;
        this.sheetId = sheetId;
        this.reportDate = reportDate;
        this.personId = personId;
        this.reportComment = reportComment;

        if (reportContents) {
            this.reportContents = reportContents;
        }

        if (reportReplies) {
            this.reportReplies = reportReplies;
        }
    }
}
