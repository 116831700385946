

























































import {Component, Vue, Prop} from "vue-property-decorator";
import * as CONFIG from "@/Config";
import axios from "axios";
import DailyReportList from "@/components/Templates/DailyReport/DailyReportList.vue";
import DailyReportSimpleSummary from "@/components/Templates/DailyReport/DailyReportSimpleSummary.vue";
import DailyReportSummary from "@/components/Templates/DailyReport/DailyReportSummary.vue";
import {DailyReportStore, DailyReportStoreModule} from "@/stores/DailyReportStore";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import {SearchDailyReportRequestModel} from "@/models/SearchDailyReportRequestModel";
import {SheetStore, SheetStoreModule} from "@/stores/SheetStore";
import moment from "moment";
import {AddDailyReportRequestModel} from "@/models/AddDailyReportRequestModel";
import {AuthStore, AuthStoreModule} from "@/stores/AuthStore";
import NotifyDialog from "@/components/Atoms/NotifyDialog.vue";
import {EditDailyReportRequestModel} from "@/models/EditDailyReportRequestModel";

@Component({
    components: {
        DailyReportList,
        DailyReportSimpleSummary,
        DailyReportSummary,
        NotifyDialog
    }
})
export default class DailyReportPage extends Vue {
    @Prop({default: false}) readonly isDark!: boolean;

    private isLoading = false;
    private isEmpty = false;
    private searchParam: SearchDailyReportRequestModel = new SearchDailyReportRequestModel(0, 0);
    private targetYearMonthList: {date: string}[] = [];
    private selectedYearMonthIndex = 0;

    private nextTargetYearMonth() {
        if (!this.searchParam.targetYearMonth) {
            return;
        }

        if (this.selectedYearMonthIndex === 0) {
            const dialog = this.$refs.NotifyDialog as NotifyDialog;
            if (!dialog) {
                return;
            }

            dialog.showAsync("Info", "これより次月の日報は投稿されていません", "閉じる");
            return;
        }

        this.selectedYearMonthIndex--;

        if (this.targetYearMonthList[this.selectedYearMonthIndex]) {
            const nextYearMonth = this.targetYearMonthList[this.selectedYearMonthIndex];
            this.searchParam.targetYearMonth = nextYearMonth.date;
        }
    }

    private prevTargetYearMonth() {
        if (!this.searchParam.targetYearMonth) {
            return;
        }

        if (this.targetYearMonthList.length <= this.selectedYearMonthIndex + 1) {
            const dialog = this.$refs.NotifyDialog as NotifyDialog;
            if (!dialog) {
                return;
            }

            dialog.showAsync("Info", "これより前月の日報は投稿されていません", "閉じる");
            return;
        }

        this.selectedYearMonthIndex++;

        if (this.targetYearMonthList[this.selectedYearMonthIndex]) {
            const nextYearMonth = this.targetYearMonthList[this.selectedYearMonthIndex];
            this.searchParam.targetYearMonth = nextYearMonth.date;
        }
    }

    /**
     * 前後ボタン以外で変更された時のインデックス更新
     */
    private changeTargetYearMonthSelectedIndex() {
        if (this.searchParam.targetYearMonth) {
            this.selectedYearMonthIndex = 0;
            for (const yearMonth of this.targetYearMonthList) {
                if (yearMonth.date === this.searchParam.targetYearMonth) {
                    break;
                }

                this.selectedYearMonthIndex++;
            }
        }
        else {
            this.selectedYearMonthIndex = 0;
        }
    }

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get authStore(): AuthStore {
        return AuthStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    private get dailyReportStore(): DailyReportStore {
        return DailyReportStoreModule;
    }

    private isListPage(): boolean {
        return this.$route.name === "daily-report";
    }

    /**
     * 担当者IDの配列を取得する
     */
    public getPersonIdArr(): number[] {
        const mPersons = this.masterInfoStore.personsMaster;
        const arr: number[] = [];
        for (const key in mPersons) {
            if (mPersons.hasOwnProperty(key)) {
                const element = mPersons[key];
                arr.push(element.id);
            }
        }
        return arr;
    }

    private async created(): Promise<void> {
        this.isLoading = true;

        if (this.masterInfoStore.workTypeMasterList.values.length === 0) {
            await this.masterInfoStore.fetchWorkTypeMaster(this.sheetStore.currentSheetId);
        }

        if (Object.keys(this.masterInfoStore.personsMaster).length === 0) {
            await this.masterInfoStore.fetchPersons();
        }

        this.loadSearchParam();

        await this.dailyReportStore.fetchAll(this.searchParam);

        const response = await axios.get<string[]>("api/daily_report/get_month_list");

        // データから選択肢を作る
        this.targetYearMonthList.length = 0;
        for (const month of response.data) {
            this.targetYearMonthList.push({date: month});
        }

        if (this.targetYearMonthList.length === 0) {
            // データがない場合は検索条件 or 現在の年月を設定
            this.targetYearMonthList.push({date: this.searchParam.targetYearMonth ? this.searchParam.targetYearMonth : moment().format("YYYY-MM")});
        }

        this.changeTargetYearMonthSelectedIndex();

        if (this.dailyReportStore.dailyReports.length === 0) {
            this.isLoading = false;
            this.isEmpty = true;
        }
        else {
            this.isLoading = false;
        }
    }

    private initUpdate = true;

    public updated() {
        this.$nextTick(
            () => {
                // 選択しているインデックス更新
                if (this.searchParam.targetYearMonth) {
                    this.changeTargetYearMonthSelectedIndex();
                }

                if (this.$route.name === "daily-report") {
                    this.updateReportList(this.initUpdate);
                    this.updateSimpleSummary();

                    this.initUpdate = false;
                }
                else {
                    this.updateSummary();
                }
            }
        );
    }

    private loadSearchParam(): void {
        const json = localStorage.getItem("dailyReport_searchParam");
        if (json) {
            Object.assign(this.searchParam, JSON.parse(json));
            this.searchParam.sheetId = this.sheetStore.currentSheetId;
        }
        else {
            this.searchParam = CONFIG.DefaultDailyReportSearchParam(
                this.sheetStore.currentSheetId,
                this.getPersonIdArr(),
                moment().format("YYYY-MM")
            );
        }
    }

    private saveSearchParam(): void {
        localStorage.setItem("dailyReport_searchParam", JSON.stringify(this.searchParam));
    }

    private async addDailyReport(newDailyReport: AddDailyReportRequestModel) {
        const dialog = this.$refs.NotifyDialog as NotifyDialog;

        const result = await this.dailyReportStore.add(newDailyReport);
        if (result) {
            if (dialog) {
                dialog.showAsync("Info", "日報を追加しました", "閉じる");
            }
            else {
                alert("日報を追加しました");
            }

            const list = this.$refs.dailyReportList as DailyReportList;
            if (!list) {
                return;
            }

            list.closeAddDailyReportDialog();

            this.searchDailyReport(this.searchParam);
        }
        else {
            if (dialog) {
                dialog.showAsync("Error", "日報の追加に失敗しました", "閉じる");
            }
            else {
                alert("日報の追加に失敗しました");
            }
        }
    }

    private async editDailyReport(dailyReport: EditDailyReportRequestModel) {
        const dialog = this.$refs.NotifyDialog as NotifyDialog;

        try {
            const result = await this.dailyReportStore.edit(dailyReport);

            if (result) {
                if (dialog) {
                    dialog.showAsync("Info", "日報を編集しました", "閉じる");
                }
                else {
                    alert("日報を編集しました");
                }

                const list = this.$refs.dailyReportList as DailyReportList;
                if (!list) {
                    return;
                }

                list.closeEditDailyReportDialog();

                this.searchDailyReport(this.searchParam);
            }
            else {
                if (dialog) {
                    dialog.showAsync("Error", "日報の編集に失敗しました", "閉じる");
                }
                else {
                    alert("日報の編集に失敗しました");
                }
            }
        }
        catch (error) {
            console.log(error);
            if (dialog) {
                dialog.showAsync("Error", "日報の編集に失敗しました", "閉じる");
            }
            else {
                alert("日報の編集に失敗しました");
            }
        }
    }

    private async searchDailyReport(searchParam: SearchDailyReportRequestModel) {
        this.isLoading = true;
        this.isEmpty = false;
        this.saveSearchParam();
        await this.dailyReportStore.fetchAll(this.searchParam);

        if (this.$route.name === "daily-report") {
            this.updateSimpleSummary();
        }
        else {
            this.updateSummary();
        }

        if (this.dailyReportStore.dailyReports.length === 0) {
            this.isEmpty = true;
        }
        this.isLoading = false;
    }

    private updateReportList(init = false) {
        const list = this.$refs.dailyReportList as DailyReportList;
        if (!list) {
            return;
        }

        if (init) {
            list.updateReplyPanel();
        }

        list.updateSearchToolbar();
    }

    private updateSimpleSummary() {
        const summary = this.$refs.dailyReportSimpleSummary as DailyReportSimpleSummary;
        if (!summary) {
            return;
        }

        summary.updateData();
    }

    private updateSummary() {
        const summary = this.$refs.dailyReportSummary as DailyReportSummary;
        if (!summary) {
            return;
        }

        summary.updateSearchToolbar();
        summary.updateData();
    }
}
