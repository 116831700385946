import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "../store";
import {DailyReportModel} from "@/models/DailyReportModel";
import {BooleanResponse} from "@/models/interfaces/Response/BooleanResponse";
import {AddDailyReportRequestModel} from "@/models/AddDailyReportRequestModel";
import {SearchDailyReportRequestModel} from "@/models/SearchDailyReportRequestModel";
import {DailyReport} from "@/models/interfaces/DailyReport/DailyReport";
import {DailyReportContent} from "@/models/interfaces/DailyReport/DailyReportContent";
import {ReplyDailyReportRequestModel} from "@/models/ReplyDailyReportModel";
import {EditDailyReportRequestModel} from "@/models/EditDailyReportRequestModel";

/**
 * 日報ストア
 */
@Module({dynamic: true, store, name: "dailyReport", namespaced: true})
export class DailyReportStore extends VuexModule {
    private _dailyReports: DailyReportModel[] = [];

    /**
     *
     */
    public get dailyReports(): DailyReportModel[] {
        return this._dailyReports;
    }

    /**
     * 日報データを初期化します
     *
     * @param map 日報データマップ
     */
    @Mutation
    public initDailyReports(arr: DailyReportModel[]): void {
        this._dailyReports = arr;
    }

    /**
     *
     *
     * @param newDailyReport 日報
     */
    @Action
    public async add(newDailyReport: AddDailyReportRequestModel): Promise<boolean> {
        const response = await axios.post<BooleanResponse>("api/daily_report/add", newDailyReport.getRequestParam());
        return response.data.result;
    }

    /**
     * 編集
     *
     * @param dailyReport 日報
     */
    @Action
    public async edit(dailyReport: EditDailyReportRequestModel): Promise<boolean> {
        const response = await axios.post<BooleanResponse>("api/daily_report/edit", dailyReport.getRequestParam());
        return response.data.result;
    }

    /**
     * 日報データを取得する
     *
     * @param searchParam 検索パラメータ
     */
    @Action
    public async fetchAll(searchParam: SearchDailyReportRequestModel): Promise<void> {
        try {
            const response = await axios.get<DailyReport[]>("api/daily_report/search" + searchParam.getQueryString());
            if (response) {
                const arr: DailyReportModel[] = [];
                for (const dailyReportData of response.data) {
                    arr.push(
                        new DailyReportModel(
                            dailyReportData.dailyReportId,
                            dailyReportData.sheetId,
                            dailyReportData.reportDate,
                            dailyReportData.personId,
                            dailyReportData.reportComment,
                            dailyReportData.reportContents,
                            dailyReportData.reportReplies
                        )
                    );
                }

                this.initDailyReports(arr);
            }
        }
        catch (ex) {
            console.error("日報データの取り込みに失敗しました.", ex);
        }
    }

    /**
     * 返信する
     *
     * @param reply 返信内容オブジェクト
     */
    @Action
    public async reply(reply: ReplyDailyReportRequestModel): Promise<boolean> {
        const response = await axios.post<BooleanResponse>("api/daily_report/reply", reply);
        return response.data.result;
    }
}

export const DailyReportStoreModule = getModule(DailyReportStore);
