




































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import * as CONFIG from "@/Config";
import { MasterInfoStore, MasterInfoStoreModule } from "@/stores/MasterInfoStore";
import { AddDailyReportRequestModel } from "@/models/AddDailyReportRequestModel";
import { AuthStore, AuthStoreModule } from "@/stores/AuthStore";
import { SheetStore, SheetStoreModule } from "@/stores/SheetStore";
import { DailyReportStore, DailyReportStoreModule } from "@/stores/DailyReportStore";
import { AddDailyReportContentRequest } from "@/models/interfaces/DailyReport/AddDailyReportContentRequest";
import moment from "moment";
import { DailyReport } from "../../models/interfaces/DailyReport/DailyReport";
import { DailyReportModel } from "../../models/DailyReportModel";
import axios from "axios";
import { GetPrevDailyReportRequestModel } from "../../models/GetPrevDailyReportRequestModel";
import NotifyDialog from "@/components/Atoms/NotifyDialog.vue";

@Component({
    components: {
        NotifyDialog
    }
})
export default class AddDailyReportDialog extends Vue {
    private valid = true;
    private lazy = false;
    private readonly multiInputMax: number = CONFIG.DailyReportMultiInputMax();
    private multiInputCount = 0;
    private isOpend = false;
    private newDailyReport: AddDailyReportRequestModel = new AddDailyReportRequestModel(
        this.sheetStore.currentSheetId,
        "",
        0,
        ""
    );

    private systemRules: ((arg1: any) => boolean | string)[] = [v => !!v || "システムを選択してください"];
    private workTypeRules: ((arg1: any) => boolean | string)[] = [v => !!v || "作業種別を選択してください"];
    private workHoursRules: ((arg1: any) => boolean | string)[] = [
        v => !!v || "作業時間を入力してください",
        (v) => {
            if (!v) {
                return "作業時間は0.1から24の範囲で入力してください";
            }

            if (v && (v <= 0 || v > 24)) {
                return "作業時間は0.1から24の範囲で入力してください";
            }

            if (v && v < 10 && v.length > 3) {
                return "作業時間は小数点1桁以下で入力してください";
            }

            if (v && v > 10 && v.length > 4) {
                return "作業時間は小数点1桁以下で入力してください";
            }

            return true;
        }
    ];
    private reportCommentRules: ((arg1: any) => boolean | string)[] = [v => v.length > 8000 ? "コメントは8000文字以下で入力してください" : true];

    private multiInputParams: AddDailyReportContentRequest[] = CONFIG.DefaultDailyReportMultiInputParams();

    private reportDate = "";

    private question1 = "";
    private question2 = "";
    private question3 = "";
    private question4 = "";
    private question5 = "";

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get authStore(): AuthStore {
        return AuthStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    private get dailyReportStore(): DailyReportStore {
        return DailyReportStoreModule;
    }

    private get isSupport() {
        return this.sheetStore.currentSheetId === 2;
    }

    /**
     * @summary タスクを追加するためのダイアログを開きます.
     * @param Task タスク
     * @returns 追加する内容
     */
    public show(): void {
        this.newDailyReport = new AddDailyReportRequestModel(this.sheetStore.currentSheetId, moment().format("YYYY-MM-DD"), this.authStore.memberId, "");

        this.isOpend = true;
        this.valid = true;
        this.multiInputCount = 0;
        this.multiInputParams = CONFIG.DefaultDailyReportMultiInputParams();
    }

    /**
     * @summary ダイアログを閉じます
     */
    public close(): void {
        this.isOpend = false;
        this.multiInputCount = 0;
        this.multiInputParams = CONFIG.DefaultDailyReportMultiInputParams();
    }

    /**
     * 日報を新規追加する
     */
    private addNewDailyReport(): void {
        if (!(this.$refs.addDailyReportForm as Vue & { validate: () => boolean }).validate()) {
            return;
        }

        const newDailyReport = (() => {
            if (!this.isSupport) {
                return new AddDailyReportRequestModel(
                    this.newDailyReport.sheetId,
                    this.newDailyReport.reportDate,
                    this.newDailyReport.personId,
                    this.newDailyReport.reportComment,
                    this.multiInputParams.filter((param) => param.systemId && param.workTypeId && param.workHours)
                );
            }
            return new AddDailyReportRequestModel(
                this.newDailyReport.sheetId,
                this.newDailyReport.reportDate,
                this.newDailyReport.personId,
                this.newDailyReport.reportComment + "[-----]" + this.question1 + "[-----]" + this.question2 + "[-----]" + this.question3 + "[-----]" + this.question4 + "[-----]" + this.question5,
                this.multiInputParams.filter((param) => param.systemId && param.workTypeId && param.workHours)
            );
        })();

        // ここでは処理せず親コンポーネントに任せる
        this.$emit("submit", newDailyReport);
    }

    /**
     * 前回投稿した日報データを取得し、ダイアログに適用する
     */
    private async fetchPrevDailyReport(): Promise<void> {
        // ストアは関係ないためここで通信処理を行う
        try {
            const dialog = this.getNotifyDialog();

            const searchParam: GetPrevDailyReportRequestModel = new GetPrevDailyReportRequestModel(this.sheetStore.currentSheetId, this.authStore.memberId);
            const response = await axios.get<DailyReport>("api/daily_report/get_prev" + searchParam.getQueryString());
            if (response && response.data.dailyReportId) {
                this.multiInputParams = CONFIG.DefaultDailyReportMultiInputParams();

                this.multiInputCount = -1;
                for (const content of response.data.reportContents) {
                    this.multiInputCount++;
                    this.multiInputParams[this.multiInputCount].systemId = content.systemId;
                    this.multiInputParams[this.multiInputCount].workTypeId = content.workTypeId;
                    this.multiInputParams[this.multiInputCount].workHours = content.workHours;
                    this.multiInputParams[this.multiInputCount].description = content.description;
                }

                if (this.multiInputCount < 0) this.multiInputCount = 0;
            }
            else {
                // 前回投稿分なし
                if (dialog) {
                    dialog.showAsync("Info", "前回分は見つかりませんでした", "閉じる");
                }
            }
        }
        catch (ex) {
            console.error("日報データの取り込みに失敗しました.", ex);
        }
    }

    private deleteMultiInput(index: number) {
        if (this.multiInputParams[index]) {
            this.multiInputParams.splice(index, 1);
            this.multiInputParams.push(CONFIG.DefaultDailyReportMultiInputParams()[0]);
            this.multiInputCount--;
        }
    }

    private toArray<TValue>(dictionary: { [key: number]: TValue }): TValue[] {
        const array = [];
        for (const key in dictionary) {
            array.push(dictionary[key]);
        }
        return array;
    }

    private getNotifyDialog(): NotifyDialog | undefined {
        return this.$refs.NotifyDialog as NotifyDialog;
    }
}
