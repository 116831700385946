import {AddDailyReportContentRequest} from "./interfaces/DailyReport/AddDailyReportContentRequest";
import {EditDailyReportRequest} from "./interfaces/DailyReport/EditDailyReportRequest";

/**
 * 日報追加リクエストパラメータモデル
 */
export class EditDailyReportRequestModel implements EditDailyReportRequest {
    dailyReportId: number;
    reportDate: string;
    reportComment: string;
    reportContents!: AddDailyReportContentRequest[];

    /**
     * コンストラクタ
     *
     *
     */
    public constructor(dailyReportId: number, reportDate: string, reportComment: string, reportContents?: AddDailyReportContentRequest[]) {
        this.dailyReportId = dailyReportId;
        this.reportDate = reportDate;
        this.reportComment = reportComment;

        if (reportContents) {
            this.reportContents = reportContents;
        }
    }

    public getRequestParam(): {} {
        return {
            dailyReportId: this.dailyReportId,
            reportDate: this.reportDate,
            reportComment: this.reportComment,
            reportContents: this.reportContents
        };
    }
}
