






































































































































































































































































































































































































































































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import * as CONFIG from "@/Config";
import { MasterInfoStore, MasterInfoStoreModule } from "@/stores/MasterInfoStore";
import { AuthStore, AuthStoreModule } from "@/stores/AuthStore";
import { SheetStore, SheetStoreModule } from "@/stores/SheetStore";
import { DailyReportStore, DailyReportStoreModule } from "@/stores/DailyReportStore";
import TooltipButton from "@/components/Atoms/TooltipButton.vue";
import AddDailyReportDialog from "@/components/Organisms/AddDailyReportDialog.vue";
import { SearchDailyReportRequestModel } from "@/models/SearchDailyReportRequestModel";
import moment from "moment";
import { IdAndName } from "@/models/interfaces/IdAndName";
import NameById from "@/components/Atoms/NameById.vue";
import LoadingTable from "@/components/Atoms/LoadingTable.vue";
import { ReplyDailyReportRequestModel } from "@/models/ReplyDailyReportModel";
import { DailyReportModel } from "@/models/DailyReportModel";
import { AddDailyReportRequestModel } from "@/models/AddDailyReportRequestModel";
import { DailyReportContent } from "../../../models/interfaces/DailyReport/DailyReportContent";
import { EditDailyReportRequestModel } from "../../../models/EditDailyReportRequestModel";
import EditDailyReportDialog from "../../Organisms/EditDailyReportDialog.vue";

/**
 * @summary 日報一覧
 */
@Component({
    components: {
        TooltipButton,
        AddDailyReportDialog,
        EditDailyReportDialog,
        NameById,
        LoadingTable
    }
})
export default class DailyReportList extends Vue {
    // 親コンポーネントから読み込むデータ
    @Prop({ default: new SearchDailyReportRequestModel(0, 0) }) searchParam!: SearchDailyReportRequestModel;
    @Prop({ default: false }) readonly isLoading!: boolean;
    @Prop({ default: false }) readonly isEmpty!: boolean;
    @Prop({ default: [] }) readonly targetYearMonthList!: { date: string }[];
    private replyPanels: { [key: number]: number | undefined } = {};

    private replyTextList: string[] = [];
    private replyTextLoadingList: boolean[] = [];
    private isJustReply = false;
    private get isSupport() {
        return SheetStoreModule.currentSheetId === 2;
    }
    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    /**
     * 担当者IDの配列を取得する
     */
    public getPersonIdArr(): number[] {
        const mPersons = this.masterInfoStore.personsMaster;
        const arr: number[] = [];
        for (const key in mPersons) {
            if (mPersons.hasOwnProperty(key)) {
                const element = mPersons[key];
                arr.push(element.id);
            }
        }
        return arr;
    }

    private get authStore(): AuthStore {
        return AuthStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    private get dailyReportStore(): DailyReportStore {
        return DailyReportStoreModule;
    }

    public get dailyReports(): DailyReportModel[] {
        return DailyReportStoreModule.dailyReports;
    }

    public updateSearchToolbar() {
        this.changePersonSelect();
    }

    public updateReplyPanel() {
        this.replyPanels = {};
        for (const dailyReport of this.dailyReportStore.dailyReports) {
            if (!this.replyPanels[dailyReport.dailyReportId]) {
                this.replyPanels[dailyReport.dailyReportId] = undefined;
            }

            if (dailyReport.reportReplies.length > 0) {
                this.replyPanels[dailyReport.dailyReportId] = 0;
            }
        }
    }

    private async created(): Promise<void> {
    }

    private getAddReportDialogRef(): AddDailyReportDialog | undefined {
        return this.$refs.addReportDialog as AddDailyReportDialog;
    }

    private showAddDailyReportDialog() {
        const addReportDialog = this.getAddReportDialogRef();
        if (!addReportDialog) {
            return;
        }

        addReportDialog.show();
    }

    public closeAddDailyReportDialog() {
        const addReportDialog = this.getAddReportDialogRef();
        if (!addReportDialog) {
            return;
        }

        addReportDialog.close();
    }

    private addDailyReport(newDailyReport: AddDailyReportRequestModel) {
        this.$emit("addDailyReport", newDailyReport);
    }

    private getEditReportDialogRef(): EditDailyReportDialog | undefined {
        return this.$refs.editReportDialog as EditDailyReportDialog;
    }

    /**
     * NOTE: サポートなら項目追加
     */
    private convertIfSupport(text: string, i?: number) {
        if (i === undefined) {
            return text || "コメントはありません";
        }

        const split = text.split("[-----]");
        return split[i] || "コメントはありません";
    }

    private showEditDailyReportDialog(dailyReport: DailyReportModel) {
        const dialog = this.getEditReportDialogRef();
        if (!dialog) {
            return;
        }

        dialog.show(dailyReport);
    }

    public closeEditDailyReportDialog() {
        const dialog = this.getEditReportDialogRef();
        if (!dialog) {
            return;
        }

        dialog.close();
    }

    private editDailyReport(dailyReport: EditDailyReportRequestModel) {
        this.$emit("editDailyReport", dailyReport);
    }

    private searchDailyReport() {
        this.$emit("searchDailyReport", this.searchParam);
    }

    private async reply(event: MouseEvent, dailyReportId: number): Promise<void> {
        if (this.replyTextList[dailyReportId]) {
            const replyTextStr: string = this.replyTextList[dailyReportId];

            this.isJustReply = true;
            this.replyTextLoadingList[dailyReportId] = true;
            await this.dailyReportStore.reply(new ReplyDailyReportRequestModel(dailyReportId, this.authStore.memberId, replyTextStr));
            this.replyTextList[dailyReportId] = "";
            this.replyTextLoadingList[dailyReportId] = false;
            this.isJustReply = false;

            // できれば中身を動的に変えたい
            // store内でやるべきか
            await this.dailyReportStore.fetchAll(this.searchParam);
            this.updateReplyPanel();
        }
    }

    private convertDailyReportContentsBySystem(reportContents: DailyReportContent[]) {
        const systemMap: { [key: number]: DailyReportContent[] } = {};
        for (const content of reportContents) {
            if (!this.masterInfoStore.systemsMaster.existsBy(content.systemId)) {
                continue;
            }

            if (!systemMap[content.systemId]) {
                systemMap[content.systemId] = [];
            }

            systemMap[content.systemId].push(content);
        }

        return systemMap;
    }

    private csvDownload(): void {
        let csvStr = "\ufeff" + "対象日,システム,業務日報,時間,担当者\n";

        const dailyWorks: Record<string, number> = {};
        for (const dailyReport of this.dailyReports) {
            const d = dailyReport.reportDate;
            for (const content of dailyReport.reportContents) {
                const person = MasterInfoStoreModule.parsonNameById(dailyReport.personId) ?? "---";
                const system = MasterInfoStoreModule.systemNameById(content.systemId);
                const workType = MasterInfoStoreModule.workTypeNameById(content.workTypeId);
                const workHours = content.workHours;
                const key = `${d}__${person}__${system}__${workType}`;

                if (dailyWorks[key] == null) {
                    dailyWorks[key] = 0;
                }

                dailyWorks[key] += workHours;
            }
        }

        for (const work of Object.entries(dailyWorks).sort((a, b) => a < b ? -1 : a > b ? 1 : 0)) {
            const items = work[0].split("__");
            csvStr += `${items[0]},${items[2]},${items[3]},${work[1]},${items[1]}\n`;
        }

        const blob = new Blob([csvStr], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "daily_report.csv";
        link.click();
    }

    private toArray<TValue>(dictionary: { [key: number]: TValue }): TValue[] {
        const array = [];
        for (const key in dictionary) {
            array.push(dictionary[key]);
        }
        return array;
    }

    private changePersonSelect(): void {
        const selections = document.querySelector(".daily-report-person-select .v-select__selections") as HTMLElement;
        if (selections) {
            const selection = selections.children;
            for (const dom of selection) {
                if (dom.classList.contains("v-select__selection") || dom.classList.contains("daily-report-person-selected-count")) {
                    selections.removeChild(dom);
                }
            }

            const div = document.createElement("div");
            div.classList.add("daily-report-person-selected-count");
            div.innerHTML = "<span>投稿者：" + this.getSelectedPersonCount() + "人選択中</span>";
            selections.insertBefore(div, selections.firstElementChild);
        }

        if (this.searchParam.personIdArr && this.searchParam.personIdArr.length > 0) {
            if (this.searchParam.personIdArr.length === Object.keys(this.masterInfoStore.personsMaster).length) {
                this.isSelectAll = true;
                this.isSelectSome = false;
            }
            else {
                this.isSelectAll = false;
                this.isSelectSome = true;
            }
        }
    }

    private getSelectedPersonCount(): number {
        if (this.searchParam.personIdArr) {
            return this.searchParam.personIdArr.length;
        }

        return 0;
    }

    private isSelectAll = false;
    private isSelectSome = false;

    private icon(): string {
        if (this.isSelectAll) return "mdi-close-box";
        if (this.isSelectSome) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
    }

    private toggle(): void {
        this.$nextTick(() => {
            if (this.isSelectAll) {
                this.searchParam.personIdArr = [];
                this.isSelectAll = false;
                this.isSelectSome = false;
            }
            else {
                const statusIdArr: number[] = [];
                for (const key in this.masterInfoStore.personsMaster) {
                    if (this.masterInfoStore.personsMaster.hasOwnProperty(key)) {
                        const element = this.masterInfoStore.personsMaster[key];
                        statusIdArr.push(element.id);
                    }
                }

                this.searchParam.personIdArr = statusIdArr;

                this.isSelectAll = true;
                this.isSelectSome = false;
            }

            this.changePersonSelect();
        });
    }
}
