






























import {Vue, Component, Watch, Prop} from "vue-property-decorator";
import {AuthStore, AuthStoreModule} from "@/stores/AuthStore";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import PieChart from "@/components/Atoms/PieChart.vue";
import {SimpleSummaryTableData} from "@/models/interfaces/DailyReport/SimpleSummaryTableData";
import {DailyReportStore, DailyReportStoreModule} from "@/stores/DailyReportStore";
import {DailyReportModel} from "@/models/DailyReportModel";

/**
 * @summary 日報一覧
 */
@Component({
    components: {
        PieChart
    }
})
export default class DailyReportSimpleSummary extends Vue {
    @Prop({default: false}) readonly isDark!: boolean;

    private summaryTableData: SimpleSummaryTableData[] = [];
    private workHourTotal = 0;

    private getSummaryByWorkTypeId(workTypeId: number): SimpleSummaryTableData | undefined {
        for (const summary of this.summaryTableData) {
            if (summary.workTypeId === workTypeId) {
                return summary;
            }
        }
    }

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get dailyReportStore(): DailyReportStore {
        return DailyReportStoreModule;
    }

    public mounted() {
        this.updateSummaryTable();
        this.updateChartData();
    }

    public updateData() {
        this.updateSummaryTable();
        this.updateChartData();
    }

    /**
     * チャートデータをstoreから読み込んでアップデートする
     */
    private updateChartData() {
        const labels: string[] = [];
        const colors: string[] = [];
        const data: number[] = [];
        const otherLabel = "その他";
        const otherColor = "#BBBBBB";
        for (const summary of this.summaryTableData) {
            const workTypeId = summary.workTypeId;
            if (this.masterInfoStore.workTypeMasterList.getBy(workTypeId).id) {
                if (labels.length < 5) {
                    labels.push(this.masterInfoStore.workTypeMasterList.getBy(workTypeId).name);
                    colors.push(this.masterInfoStore.workTypeMasterList.getColorBy(workTypeId, this.isDark));
                    data.push(summary.workHoursSum);
                }
                else {
                    if (5 in data) {
                        data[5] += summary.workHoursSum;
                    }
                    else {
                        data[5] = summary.workHoursSum;
                    }
                }
            }
        }

        if (data.length > 5) {
            labels.push(otherLabel);
            colors.push(otherColor);
        }

        const PieChart = this.$refs.PieChart as PieChart;
        if (PieChart) {
            PieChart.updateChartData(labels, colors, data);
        }
    }

    /**
     * 集計テーブルをstoreから読み込んだデータでアップデートする
     */
    private updateSummaryTable() {
        // 元データを初期化
        this.workHourTotal = 0;
        this.summaryTableData = [];
        const _summaryTableData: SimpleSummaryTableData[] = [];
        for (const workType of this.masterInfoStore.workTypeMasterList.values) {
            const workTypeName = workType.name;
            _summaryTableData[workType.id] = {workTypeId: workType.id, workTypeName, workHoursSum: 0};
        }

        // 作業種別IDごとの合計
        for (const dailyReportData of this.dailyReportStore.dailyReports) {
            for (const reportContent of dailyReportData.reportContents) {
                _summaryTableData[reportContent.workTypeId].workHoursSum += reportContent.workHours;

                this.workHourTotal += reportContent.workHours;

                // 誤差が出るので10をかけて四捨五入からの10で割る
                _summaryTableData[reportContent.workTypeId].workHoursSum = Math.round(_summaryTableData[reportContent.workTypeId].workHoursSum * 10) / 10;
                this.workHourTotal = Math.round(this.workHourTotal * 10) / 10;
            }
        }

        // ソート
        _summaryTableData.sort(this.sortSummaryTableData);

        // lengthがおかしくなっているのでfilterで再生成
        this.summaryTableData = _summaryTableData.filter((value) => value);
    }

    private sortSummaryTableData(a: SimpleSummaryTableData, b: SimpleSummaryTableData) {
        return a.workHoursSum === b.workHoursSum ? 0 : a.workHoursSum < b.workHoursSum ? 1 : -1;
    }
}
