import {ReplyDailyReportRequest} from "./interfaces/DailyReport/ReplyDailyReportRequest";

/**
 * 日報返信リクエストパラメータモデル
 */
export class ReplyDailyReportRequestModel implements ReplyDailyReportRequest {
    dailyReportId: number;
    personId: number;
    replyText: string;

    /**
     * コンストラクタ
     *
     *
     */
    public constructor(dailyReportId: number, personId: number, replyText: string) {
        this.dailyReportId = dailyReportId;
        this.personId = personId;
        this.replyText = replyText;
    }
}
