import {AddDailyReportRequest} from "./interfaces/DailyReport/AddDailyReportRequest";
import {AddDailyReportContentRequest} from "./interfaces/DailyReport/AddDailyReportContentRequest";

/**
 * 日報追加リクエストパラメータモデル
 */
export class AddDailyReportRequestModel implements AddDailyReportRequest {
    sheetId: number;
    reportDate: string;
    personId: number;
    reportComment: string;
    reportContents!: AddDailyReportContentRequest[];

    /**
     * コンストラクタ
     *
     *
     */
    public constructor(sheetId: number, reportDate: string, personId: number, reportComment: string, reportContents?: AddDailyReportContentRequest[]) {
        this.sheetId = sheetId;
        this.reportDate = reportDate;
        this.personId = personId;
        this.reportComment = reportComment;

        if (reportContents) {
            this.reportContents = reportContents;
        }
    }

    public getRequestParam(): {} {
        return {
            sheetId: this.sheetId,
            reportDate: this.reportDate,
            personId: this.personId,
            reportComment: this.reportComment,
            reportContents: this.reportContents
        };
    }
}
