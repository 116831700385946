
import {Component, Prop, Watch, Mixins} from "vue-property-decorator";
import {Line, mixins} from "vue-chartjs";
import Chart, {ChartDataSets} from "chart.js";

/**
 * @summary 折れ線グラフ
 */
@Component
export default class LineChart extends Mixins(Line, mixins.reactiveData) {
    @Prop({default: false}) readonly isDark!: boolean;

    private isRendered = false;
    public _chartOptions: Chart.ChartOptions = {
        elements: {
            line: {
                fill: false
            }
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        // Y軸を0から始める
                        beginAtZero: true
                    }
                }
            ]
        },

        // レスポンシブ対応を有効化
        responsive: true,

        // アスペクト比を保たない
        maintainAspectRatio: false
    };

    public updateChartData(labels: (string | string[])[], datasets: ChartDataSets[]) {
        if (this.isDark) {
            Chart.defaults.global.defaultFontColor = "#FFFFFF";
        }
        else {
            Chart.defaults.global.defaultFontColor = "#000000";
        }

        this.chartData = {labels, datasets};

        if (!this.isRendered) {
            this.renderChart(this.chartData, this._chartOptions);
            this.isRendered = true;
        }
    }

    public mounted() {
        this.renderChart(this.chartData, this._chartOptions);
    }
}
