
import {Component, Prop, Watch, Mixins} from "vue-property-decorator";
import {Pie, mixins} from "vue-chartjs";
import Chart from "chart.js";

/**
 * @summary 日報一覧
 */
@Component
export default class PieChart extends Mixins(Pie, mixins.reactiveData) {
    @Prop({default: false}) readonly isDark!: boolean;

    @Prop() public reactiveChartLabels!: string[];
    @Prop() public reactiveChartData!: number[];
    @Prop() public reactiveChartColors!: string[];
    public chartOptions: Chart.ChartOptions = {
        cutoutPercentage: 50
    };

    public updateChartData(labels: string[], colors: string[], data: number[]) {
        if (this.isDark) {
            Chart.defaults.global.defaultFontColor = "#FFFFFF";
        }
        else {
            Chart.defaults.global.defaultFontColor = "#000000";
        }

        this.chartData = {
            labels,
            datasets: [
                {
                    label: "Data One",
                    backgroundColor: colors,
                    data
                }
            ]
        };
    }

    public mounted() {
        this.updateChartData(this.reactiveChartLabels, this.reactiveChartColors, this.reactiveChartData);
    }
}
