





















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class TooltipButton extends Vue {
    @Prop({default: ""}) readonly color!: string;
    @Prop({default: ""}) readonly buttonClass!: string;
    @Prop({default: ""}) readonly tooltipText!: string;
    @Prop({default: ""}) readonly icon!: string;
    @Prop({default: ""}) readonly to!: string;
    @Prop({default: 4}) readonly elevation!: number;
    @Prop({default: false}) readonly disabled!: boolean;
}
