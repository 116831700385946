import {GetPrevDailyReportRequest} from "./interfaces/DailyReport/GetPrevDailyReportRequest";

/**
 * @summary 前回投稿した日報取得で使用する検索パラメータを提供します
 */
export class GetPrevDailyReportRequestModel implements GetPrevDailyReportRequest {
    sheetId: number;
    personId: number;

    /**
     * @summary コンストラクタ
     */
    public constructor(sheetId: number, personId: number) {
        this.sheetId = sheetId;
        this.personId = personId;
    }

    public getQueryString(): string {
        const query: string[] = [];

        if (this.sheetId) {
            query.push(`sheetId=${this.sheetId}`);
        }

        if (this.personId) {
            query.push(`personId=${this.personId}`);
        }

        return "?" + query.join("&");
    }
}
