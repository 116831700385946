
































































































































































import {Vue, Component, Watch, Prop} from "vue-property-decorator";
import {AuthStore, AuthStoreModule} from "@/stores/AuthStore";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import TooltipButton from "@/components/Atoms/TooltipButton.vue";
import AddDailyReportDialog from "@/components/Organisms/AddDailyReportDialog.vue";
import LineChart from "@/components/Atoms/LineChart.vue";
import {SearchDailyReportRequestModel} from "@/models/SearchDailyReportRequestModel";
import {SheetStore, SheetStoreModule} from "@/stores/SheetStore";
import {DailyReportStoreModule, DailyReportStore} from "@/stores/DailyReportStore";
import {AddDailyReportRequestModel} from "../../../models/AddDailyReportRequestModel";
import {SimpleSummaryTableData} from "../../../models/interfaces/DailyReport/SimpleSummaryTableData";
import LoadingTable from "@/components/Atoms/LoadingTable.vue";
import moment from "moment";
import {ChartDataSets} from "chart.js";

/**
 * @summary 日報一覧
 */
@Component({
    components: {
        TooltipButton,
        AddDailyReportDialog,
        LineChart,
        LoadingTable
    }
})
export default class DailyReportSummary extends Vue {
    // 親コンポーネントから読み込むデータ
    @Prop({default: new SearchDailyReportRequestModel(0, 1)}) searchParam!: SearchDailyReportRequestModel;
    @Prop({default: true}) readonly isLoading!: boolean;
    @Prop({default: false}) readonly isEmpty!: boolean;
    @Prop({default: []}) readonly targetYearMonthList!: {date: string}[];
    @Prop({default: false}) readonly isDark!: boolean;

    private loaderType = "card";

    private summaryTableData: SimpleSummaryTableData[] = [];

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    /**
     * 担当者IDの配列を取得する
     */
    public getPersonIdArr(): number[] {
        const mPersons = this.masterInfoStore.personsMaster;
        const arr: number[] = [];
        for (const key in mPersons) {
            if (mPersons.hasOwnProperty(key)) {
                const element = mPersons[key];
                arr.push(element.id);
            }
        }
        return arr;
    }

    private get authStore(): AuthStore {
        return AuthStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    private get dailyReportStore(): DailyReportStore {
        return DailyReportStoreModule;
    }

    private getAddReportDialogRef(): AddDailyReportDialog | undefined {
        return this.$refs.addReportDialog as AddDailyReportDialog;
    }

    private showAddDailyReportDialog() {
        const addReportDialog = this.getAddReportDialogRef();
        if (!addReportDialog) {
            return;
        }

        addReportDialog.show();
    }

    public closeAddDailyReportDialog() {
        const addReportDialog = this.getAddReportDialogRef();
        if (!addReportDialog) {
            return;
        }

        addReportDialog.close();
    }

    private addDailyReport(newDailyReport: AddDailyReportRequestModel) {
        this.$emit("addDailyReport", newDailyReport);
    }

    private searchDailyReport() {
        this.$emit("searchDailyReport", this.searchParam);
    }

    private toArray<TValue>(dictionary: {[key: number]: TValue}): TValue[] {
        const array = [];
        for (const key in dictionary) {
            array.push(dictionary[key]);
        }
        return array;
    }

    private changePersonSelect(): void {
        const selections = document.querySelector(".daily-report-person-select .v-select__selections") as HTMLElement;
        if (selections) {
            const selection = selections.children;
            for (const dom of selection) {
                if (dom.classList.contains("v-select__selection") || dom.classList.contains("daily-report-person-selected-count")) {
                    selections.removeChild(dom);
                }
            }

            const div = document.createElement("div");
            div.classList.add("daily-report-person-selected-count");
            div.innerHTML = "<span>投稿者：" + this.getSelectedPersonCount() + "人選択中</span>";
            selections.insertBefore(div, selections.firstElementChild);
        }
    }

    private getSelectedPersonCount(): number {
        if (this.searchParam.personIdArr) {
            return this.searchParam.personIdArr.length;
        }

        return 0;
    }

    public updateSearchToolbar() {
        this.changePersonSelect();
    }

    public updateData() {
        this.updateChartData();
    }

    /**
     * チャートデータをstoreから読み込んでアップデートする
     */
    private updateChartData() {
        // 作業種別ごとに日別の集計データを生成する

        // 選択されている月の日を配列化
        const labels: (string | string[])[] = [];
        const baseDay = this.searchParam.targetYearMonth ? moment(this.searchParam.targetYearMonth, "YYYY-MM") : moment();
        for (let day = 1; day <= baseDay.daysInMonth(); day++) {
            labels.push(day + "日");
        }

        // 初期化
        const dailyWorkHoursSum: {[key: number]: number[]} = {}; // 作業種別ごとの作業時間
        for (const workType of this.masterInfoStore.workTypeMasterList.values) {
            for (const label of labels) {
                if (!dailyWorkHoursSum[workType.id]) {
                    dailyWorkHoursSum[workType.id] = [];
                }
                dailyWorkHoursSum[workType.id].push(0);
            }
        }

        // 作業種別ID_日付ごとの合計
        for (const dailyReportData of this.dailyReportStore.dailyReports) {
            const day = parseInt(moment(dailyReportData.reportDate, "YYYY-MM-DD").format("D"));
            for (const reportContent of dailyReportData.reportContents) {
                dailyWorkHoursSum[reportContent.workTypeId][(day - 1)] += reportContent.workHours;
            }
        }

        const datasets: ChartDataSets[] = [];
        for (const workTypeId in dailyWorkHoursSum) {
            if (!dailyWorkHoursSum.hasOwnProperty(workTypeId)) {
                continue;
            }

            const dataLabel: string = this.masterInfoStore.workTypeMasterList.getBy(parseInt(workTypeId)).name;
            const colors: string = this.masterInfoStore.workTypeMasterList.getColorBy(parseInt(workTypeId), this.isDark);
            const data: number[] = [];

            const summaryData = dailyWorkHoursSum[workTypeId];
            for (const key in summaryData) {
                if (!summaryData.hasOwnProperty(key)) {
                    continue;
                }

                const workHoursSum = summaryData[key];
                data.push(workHoursSum);
            }

            datasets.push(
                {
                    lineTension: 0,
                    label: dataLabel,
                    borderColor: colors,
                    data,
                    fill: false
                }
            );
        }

        const LineChart = this.$refs.LineChart as LineChart;
        if (LineChart) {
            LineChart.updateChartData(labels, datasets);
        }
    }
}
