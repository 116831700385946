




































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import * as CONFIG from "@/Config";
import { MasterInfoStore, MasterInfoStoreModule } from "@/stores/MasterInfoStore";
import { AuthStore, AuthStoreModule } from "@/stores/AuthStore";
import { SheetStore, SheetStoreModule } from "@/stores/SheetStore";
import { DailyReportStore, DailyReportStoreModule } from "@/stores/DailyReportStore";
import moment from "moment";
import { DailyReport } from "../../models/interfaces/DailyReport/DailyReport";
import { DailyReportModel } from "../../models/DailyReportModel";
import axios from "axios";
import { GetPrevDailyReportRequestModel } from "../../models/GetPrevDailyReportRequestModel";
import NotifyDialog from "@/components/Atoms/NotifyDialog.vue";
import { EditDailyReportRequestModel } from "@/models/EditDailyReportRequestModel";
import { EditDailyReportContentRequest } from "@/models/interfaces/DailyReport/EditDailyReportContentRequest";
;

@Component({
    components: {
        NotifyDialog
    }
})
export default class EditDailyReportDialog extends Vue {
    private valid = true;
    private lazy = false;
    private readonly multiInputMax: number = CONFIG.DailyReportMultiInputMax();
    private multiInputCount = 0;
    private isOpend = false;
    private baseDailyReport: DailyReportModel = new DailyReportModel(0, 0, "", 0, "");
    private editDailyReport: EditDailyReportRequestModel = new EditDailyReportRequestModel(0, "", "");

    private systemRules: ((arg1: any) => boolean | string)[] = [v => !!v || "システムを選択してください"];
    private workTypeRules: ((arg1: any) => boolean | string)[] = [v => !!v || "作業種別を選択してください"];
    private workHoursRules: ((arg1: any) => boolean | string)[] = [
        v => !!v || "作業時間を入力してください",
        (v) => {
            if (!v) {
                return "作業時間は0.1から24の範囲で入力してください";
            }

            if (v && (v <= 0 || v > 24)) {
                return "作業時間は0.1から24の範囲で入力してください";
            }

            if (v && v < 10 && v.length > 3) {
                return "作業時間は小数点1桁以下で入力してください";
            }

            if (v && v > 10 && v.length > 4) {
                return "作業時間は小数点1桁以下で入力してください";
            }

            return true;
        }
    ];
    private reportCommentRules: ((arg1: any) => boolean | string)[] = [v => v.length > 8000 ? "コメントは8000文字以下で入力してください" : true];

    private multiInputParams: EditDailyReportContentRequest[] = CONFIG.DefaultDailyReportMultiInputParamsForEdit();

    private reportDate = "";

    private question1 = "";
    private question2 = "";
    private question3 = "";
    private question4 = "";
    private question5 = "";

    private get isSupport() {
        return this.sheetStore.currentSheetId === 2;
    }


    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get authStore(): AuthStore {
        return AuthStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    private get dailyReportStore(): DailyReportStore {
        return DailyReportStoreModule;
    }

    /**
     * @summary タスクを追加するためのダイアログを開きます.
     * @returns 追加する内容
     */
    public show(dailyReport: DailyReportModel): void {
        if (this.isSupport) {
            const text = dailyReport.reportComment.split("[-----]");

            this.editDailyReport.reportComment = text[0];
            this.question1 = text[1];
            this.question2 = text[2];
            this.question3 = text[3];
            this.question4 = text[4];
            this.question5 = text[5];
        }
        else {
            this.editDailyReport.reportComment = dailyReport.reportComment;
        }

        this.editDailyReport.dailyReportId = dailyReport.dailyReportId;
        this.editDailyReport.reportDate = dailyReport.reportDate;
        this.editDailyReport.reportContents = dailyReport.reportContents;

        this.isOpend = true;
        this.valid = true;
        this.multiInputCount = 0;
        this.multiInputParams = CONFIG.DefaultDailyReportMultiInputParamsForEdit();

        this.multiInputCount = -1;
        for (const content of dailyReport.reportContents) {
            this.multiInputCount++;
            this.multiInputParams[this.multiInputCount].systemId = content.systemId;
            this.multiInputParams[this.multiInputCount].workTypeId = content.workTypeId;
            this.multiInputParams[this.multiInputCount].workHours = content.workHours;
            this.multiInputParams[this.multiInputCount].description = content.description;
        }

        if (this.multiInputCount < 0) this.multiInputCount = 0;
    }

    /**
     * @summary ダイアログを閉じます
     */
    public close(): void {
        this.isOpend = false;
        this.multiInputCount = 0;
        this.multiInputParams = CONFIG.DefaultDailyReportMultiInputParamsForEdit();
    }

    /**
     * 日報を新規追加する
     */
    private edit(): void {
        if (!(this.$refs.editDailyReportForm as Vue & { validate: () => boolean }).validate()) {
            return;
        }

        const dailyReport = (() => {
            if (!this.isSupport) {
                return new EditDailyReportRequestModel(
                    this.editDailyReport.dailyReportId,
                    this.editDailyReport.reportDate,
                    this.editDailyReport.reportComment,
                    this.multiInputParams.filter((param) => param.systemId && param.workTypeId && param.workHours)
                );
            }
            return new EditDailyReportRequestModel(
                this.editDailyReport.dailyReportId,
                this.editDailyReport.reportDate,
                this.editDailyReport.reportComment + "[-----]" + this.question1 + "[-----]" + this.question2 + "[-----]" + this.question3 + "[-----]" + this.question4 + "[-----]" + this.question5,
                this.multiInputParams.filter((param) => param.systemId && param.workTypeId && param.workHours)
            );
        })();

        // ここでは処理せず親コンポーネントに任せる
        this.$emit("submit", dailyReport);
    }

    private deleteMultiInput(index: number) {
        if (this.multiInputParams[index]) {
            this.multiInputParams.splice(index, 1);
            this.multiInputParams.push(CONFIG.DefaultDailyReportMultiInputParams()[0]);
            this.multiInputCount--;
        }
    }

    private toArray<TValue>(dictionary: { [key: number]: TValue }): TValue[] {
        const array = [];
        for (const key in dictionary) {
            array.push(dictionary[key]);
        }
        return array;
    }

    private getNotifyDialog(): NotifyDialog | undefined {
        return this.$refs.NotifyDialog as NotifyDialog;
    }
}
